import React from "react";
import Layout from "./src/components/layout/layout";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-loading-skeleton/dist/skeleton.css";
import SSRProvider from "react-bootstrap/SSRProvider";

export const wrapPageElement = ({ element, props }) => {
  return (
    <SSRProvider>
      <Layout {...props}>{element}</Layout>
    </SSRProvider>
  );
};
