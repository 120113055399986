import styled from "@emotion/styled";
import Img from "gatsby-image";
import { mq } from "../../../styles/media-query";

const CustomImage = styled(Img)`
  ${(props) => props.boxShadow && `box-shadow: ${props.boxShadow}`};
  ${(props) => props.borderRadius && `border-radius: ${props.borderRadius}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}`};
  ${(props) => props.height && `height: ${props.height}`};

  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-name: ${(props) => props.widthAnimation || ""};
  width: ${(props) => props.width || "100%"};
  ${(props) => ({ ...props })};
  ${mq["sm"]} {
    width: ${(props) => props.mobile_width || props.width};
  }
  ${mq["md"]} {
    width: ${(props) => props.tablet_width || props.width};
  }
`;

export default CustomImage;
