import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "react-bootstrap";

export const MainSection = styled.section`
  position: relative;
  padding: 120px 0px;
  background-color: white;
  @media (max-width: ${(props) => props.theme.breakpoints.phone}) {
    padding: 60px 0px;
  }
`;
export const ShadowContainer = styled.section`
  border-radius: 20px;
  min-height: 290px;
  background-color: #eceef8;
  margin-top: 70px;
  margin-left: 18px;
`;
export const InnerShadow = styled.section`
  border-radius: 20px;
  height: 290px;
  margin: -18px 18px -18px -18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 26px;
`;

export const FeatureAlterContainer = styled.section`
  margin-top: 170px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 30px;
  }
`;
export const HomeSectionHeader = styled.section`
  margin-bottom: -80px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin-bottom: 0px;
  }
`;

export const PaddedRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 60px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin-top: 20px;
    padding-bottom: 20px;
  }
`;

export const ContainerWrapper = styled(Container)`
  max-width: 1200px;
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  color: black;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 30px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.phone}) {
    font-size: 1.5em;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.phone}) {
    margin-bottom: 25px;
  }
`;

export const ImageCustomized = styled.div`
  img {
    max-width: 150px;
  }
`;

export const FeatureTitle = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  color: #0d2b73;
  font-size: 25px;
  line-height: 30px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 20px;
  }
`;
export const Icon = styled(FontAwesomeIcon)`
  font-size: 3em;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 2em;
  }
`;

export const UnOrderedList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ListItem = styled.li`
  color: #777;
  font-size: 1rem;
  padding-bottom: 15px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 1px;
    background: #c9c9c9;
    bottom: 6px;
  }
  &:last-child:after {
    display: none;
  }
`;

export const CenterPara = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  margin: 20px 0 20px 0;
  text-align: left;
`;

export const CommentSection = styled.div`
  width: 100%;
  padding: 20px;
  background: #eceef8;
  border-radius: 30px;
`;

export const Comment = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #282c40;
  margin-right: 10px;
  margin-bottom: 0px;
`;

export const UserName = styled.p`
  font-weight: 700;
  font-size: 16px;
  color: #0d2b73;
  text-align: right;
  margin-bottom: 0px;
  margin-top: 7px;
`;

export const DatabaseSupportWrapper = styled.div`
  margin-top: 120px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakpoints.phone}) {
    margin-top: 60px;
  }
`;

export const SupportPara = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-top: 16px;
  margin-bottom: 25px;
  max-width: 570px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
`;

export const DatabasesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
  max-width: 1000px;
  width: 100%;
`;
export const TouchPara = styled.p`
  font-weight: 700;
  font-size: 22px;
  line-height: 36px;
  color: #14161f;
  max-width: 460px;
  width: 100%;
  margin: 32px 0px;
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 16px;
    line-height: 24px;
  }
`;
export const TouchTitle = styled.p`
  font-family: ${(props) => props.titleFont || "sans-serif"};
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 0px;
  color: ${(props) => props.titleColor || "#14161f"};
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 24px;
    line-height: 30px;
  }
`;
