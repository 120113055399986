import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import whiteLogo from "../../../assets/images/brand-logo-white.svg";
import twitter from "../../../assets/images/small-twitter.svg";
import github from "../../../assets/images/small-github.svg";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const FooterEl = styled.footer`
  background: ${(props) => props.theme.colors.clearText};
  padding: 50px 100px;
  @media (max-width: ${(props) => props.theme.breakpoints.laptop}) {
    padding: 50px 50px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 50px 30px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.phone}) {
    padding: 50px 20px;
  }
`;

const LinkItem = styled(Link)`
  color: #fff;
  display: block;
  font-weight: 400;
  font-size: 16px;
  transition: all 200ms ease-in-out;
  margin: 10px 0px 25px 0px;
  white-space: nowrap;
  text-align: left;
  &:hover {
    color: ${(props) => props.theme.colors.buttonText};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.phone}) {
    margin: 10px 0px;
    text-align: center;
  }
`;
const CopyRight = styled.p`
  margin: 60px 0 0 0;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
`;

const Footer = () => {
  return (
    <FooterEl>
      <Row className="align-items-start justify-content-between flex-wrap no-gutters">
        <Col xs={10} sm={5} md={4} xl={3}>
          <Link to="/">
            <img src={whiteLogo} className="img-fluid" alt="Tokern" />
          </Link>
          <div className="d-flex justify-content-start ml-3 pl-5 mt-3">
            <a
              target="_blank"
              href="https://github.com/tokern/piicatcher"
            >
              <img className="mr-2" src={github} alt="Tokern Open-Source PIICatcher GitHub"/>
            </a>
            <a target="_blank" href="https://twitter.com/tokernhq">
              <img src={twitter} alt="Tokern Twitter"/>
            </a>
          </div>
        </Col>
        <Col md={7}>
          <Row className="d-flex align-items-start justify-content-between flex-wrap no-gutters mt-5 mt-md-0">
            <Col xs={5} sm={3} className="my-4 my-md-0 mx-1">
              <LinkItem to="/">Home</LinkItem>
              <LinkItem target="_blank" to="https://borneo.io/company/">About Borneo</LinkItem>
            </Col>
            <Col xs={5} sm={3} className="my-4 my-md-0 mx-1">
              <LinkItem
                target="_blank"
                to="https://github.com/tokern/piicatcher"
              >
                GitHub
              </LinkItem>
              <LinkItem target={"_self"} href="https://docs.tokern.io">
                Docs
              </LinkItem>
              <LinkItem to="/blog">Blog</LinkItem>
            </Col>
            <Col xs={12} sm={4} className="my-4 my-md-0 mx-1">
              <LinkItem target="_blank" to="https://tokerncommunity.slack.com/join/shared_invite/zt-1nc8f1qip-y5hYnrinl7l_vmLtxnk2Qw">Feature Requests</LinkItem>
              <LinkItem target="_blank" to="https://tokerncommunity.slack.com/join/shared_invite/zt-1nc8f1qip-y5hYnrinl7l_vmLtxnk2Qw">Tokern Roadmap</LinkItem>
              <LinkItem
                target="_blank"
                to="https://tokerncommunity.slack.com/join/shared_invite/zt-1nc8f1qip-y5hYnrinl7l_vmLtxnk2Qw"
              >
                Slack Community
              </LinkItem>
            </Col>
          </Row>
        </Col>
      </Row>
      <CopyRight>© 2023 Tokern</CopyRight>
    </FooterEl>
  );
};

export default Footer;
