import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import Image from "./image/";

const CustomButton = ({
  icon,
  alt,
  iconWidth,
  btnText,
  flexDirection,
  textColor,
  buttonStyles,
  buttonBg,
  btnBorder,
  link,
  target,
}) => {
  const ButtonWrapper = styled.button`
    border-radius: 10px;
    background: ${buttonBg || "#0d2b73"};
    border: ${btnBorder || "1px solid #0d2b73"};
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;
    flex-direction: ${flexDirection || "row"};
    ${buttonStyles}
  `;

  const Text = styled.p`
    font-weight: 600;
    font-size: 14px;
    color: ${textColor || "#ffff"};
    margin: ${icon ? "0px 5px" : "0px"};
  `;

  return (
    <Link target={target || "_self"} to={link || ""}>
      <ButtonWrapper>
        {icon && <img src={icon} alt={alt} width={iconWidth} />}
        <Text>{btnText}</Text>
      </ButtonWrapper>
    </Link>
  );
};

export default CustomButton;
