import React from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import styled, { ThemeProvider } from "styled-components";
import theme from "../../styles/theme";
import { GlobalStyle } from "../../styles/global";

const Layout = (props) => {
  const HeaderWrapper = styled.div`
    height: 60px;
    @media (max-width: ${(props) => props.theme.breakpoints.phone}) {
      height: 60px;
    }
  `;
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Header />
        <HeaderWrapper />
        <div id="layout">{props.children}</div>
        <Footer />
      </ThemeProvider>
    </React.Fragment>
  );
};

export default Layout;
