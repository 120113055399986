import styled from "styled-components";
import { Link } from "gatsby";
import { Icon } from "./features";

export const HeaderLink = styled(Link)`
  color: ${(props) => props.theme.colors.clearText};
  font-size: 0.9rem;
  transition: all 200ms ease-in-out;
  &:hover {
    color: ${(props) => props.theme.colors.clearBackground};
  }
`;

export const HeaderExtLink = styled(Link)`
  color: ${(props) => props.theme.colors.clearText};
  font-size: 14px;
  transition: all 200ms ease-in-out;
  &:hover {
    color: ${(props) => props.theme.colors.clearBackground};
  }
`;

export const TryBorneo = styled(Link)`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Primary / Primary */

  color: #00953b;
  box-sizing: border-box;
  width: 99px;
  height: 35px;
  left: 4px;
  top: -1px;
  justify-content: center;
  border: 3px solid #00953b;
  border-radius: 10px;
`;

export const Github = styled(Link)`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Primary / Primary */

  color: white;
  box-sizing: border-box;
  width: 150px;
  height: 35px;
  top: -1px;
  justify-content: center;
  border: 3px solid #0d2b73;
  background: #0d2b73;
  border-radius: 10px;

  ::selection {
    color: lightcyan;
    background: #0d2b73;
  }

  :hover {
    color: white;
    background: #0d2b73;
  }
`;

export const GithubIcon = styled(Icon)`
  max-height: 50%;
  background: #0d2b73;
  color: white;
  justify-content: center;
  margin: unset;
`;
