import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MobileMenu from "../../common/mobilemenu";
import styled from "styled-components";
import { HeaderExtLink, HeaderLink } from "../../../styles/header";
import { UnOrderedList } from "../../../styles/features";
import Dropdown from "react-bootstrap/Dropdown";
import { Logo } from "../../common/logo";
import CustomButton from "../../common/customButton";
import github from "../../../assets/images/button-github.svg";

export const HeaderEl = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease-in-out;
  height: 84px;
  background: #eceef8;
  padding: 0px 40px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    padding: 30px;
    border-bottom: solid 1px #f1f1f1;
    z-index: 5;
`;

const Nav = styled.nav`
  text-align: right;
`;

const Item = styled.li`
  display: inline-block;
  margin: 0 18px;
  &:last-child {
    margin-right: 0;
  }
`;

const HeaderDrop = styled.button`
  color: ${(props) => props.theme.colors.clearText};
  background-color: ${(props) => props.theme.colors.white};
  transition: all 200ms ease-in-out;
  &:hover {
    color: ${(props) => props.theme.colors.clearBackground};
  }
  border: 0;
  box-shadow: none;
  font-size: 14px;
`;

const Header = () => {
  return (
    <HeaderEl id="header">
      <Row className="align-items-center justify-content-between no-gutters w-100">
        <Col lg={3} xs={6} md={6}>
          <Logo />
        </Col>
        <Col lg={8} className="d-none d-lg-block">
          <Nav>
            <UnOrderedList>
              <Item>
                <HeaderLink to="/piicatcher/">PII Catcher</HeaderLink>
              </Item>
              <Item>
                <HeaderLink to="/data-lineage/">Data Lineage</HeaderLink>
              </Item>
              <Item>
                <Dropdown>
                  <Dropdown.Toggle
                    className="bg-transparent"
                    style={{fontSize: "0.9rem"}}
                    as={HeaderDrop}
                    id="dropdown-basic"
                  >
                    More
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      style={{ color: "#0D2B73" }}
                      as={HeaderLink}
                      to="/blog"
                    >
                      Blog
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{ color: "#0D2B73" }}
                      as={HeaderLink}
                      href="https://docs.tokern.io"
                      target={"_self"}
                    >
                      Docs
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={HeaderLink}
                      style={{ color: "#0D2B73" }}
                      target="_blank"
                      href="https://join.slack.com/t/tokerncommunity/shared_invite/zt-1nc8f1qip-y5hYnrinl7l_vmLtxnk2Qw"
                    >
                      Slack community
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{ color: "#0D2B73" }}
                      as={HeaderLink}
                      to="/about/"
                    >
                      About us
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Item>
              <Item>
                <CustomButton
                  icon={github}
                  alt="Download"
                  iconWidth="20px"
                  btnText="Download"
                  link="https://github.com/tokern/piicatcher"
                  target={"_blank"}
                />
              </Item>
              <Item>
                <CustomButton
                  btnText="Try Borneo"
                  btnBorder="3px solid #00953B"
                  buttonBg="transparent"
                  textColor="#00953B"
                  buttonStyles="border-radius: 10px; padding: 5px 10px;"
                  link={"https://www.borneo.io/"}
                  target={"_blank"}
                />
              </Item>
            </UnOrderedList>
          </Nav>
        </Col>
        <Col xs={1} md={1} className={"d-block d-lg-none"}>
          <MobileMenu />
        </Col>
      </Row>
    </HeaderEl>
  );
};

export default Header;
