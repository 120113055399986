import React from "react";
import styled from "styled-components";
import { slide as Menu } from "react-burger-menu";
import { HeaderExtLink, HeaderLink } from "../../styles/header";

const MenuWrapper = styled.div``;

const Separator = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
`;

const styles = {
  bmBurgerButton: {
    position: "relative",
    width: "32px",
    height: "24px",
  },
  bmBurgerBars: {
    background: "#373a47",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#c9c9c9",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: "#ffffff",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
  },
  bmItem: {},
  bmItemHover: {
    color: "white",
  },
  bmOverlay: {
    background: "rgba(255, 255, 255, 1)",
    width: "300px",
  },
};

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
  }

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu() {
    this.setState({ menuOpen: false });
  }

  // This can be used to toggle the menu, e.g. when using a custom icon
  // Tip: You probably want to hide either/both default icons if using a custom icon
  // See https://github.com/negomi/react-burger-menu#custom-icons
  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  }

  render() {
    return (
      <MenuWrapper>
        <Menu
          styles={styles}
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          right
          disableAutoFocus
        >
          <HeaderLink to="/" onClick={() => this.closeMenu()}>
            Home
          </HeaderLink>
          <hr />
          <HeaderLink to="/piicatcher/" onClick={() => this.closeMenu()}>
            PII Catcher
          </HeaderLink>
          <HeaderLink to="/data-lineage/" onClick={() => this.closeMenu()}>
            Data Lineage
          </HeaderLink>
          <Separator />
          <HeaderLink to="/blog" onClick={() => this.closeMenu()}>
            Blog
          </HeaderLink>
          <HeaderExtLink
            href="https://docs.tokern.io"
            target={"_self"}
            onClick={() => this.closeMenu()}
          >
            Docs
          </HeaderExtLink>
          <HeaderExtLink to="/about/" onClick={() => this.closeMenu()}>
            About
          </HeaderExtLink>
        </Menu>
      </MenuWrapper>
    );
  }
}

export default MobileMenu;
