import React from 'react'
import styled from "styled-components"
import { Link } from "gatsby"
import brandLogo from "../../assets/images/brand-logo.svg"

const LogoLink = styled(Link)`
  max-width: 200px;
  display: block
`

export const Logo = () => {
  return(
    <LogoLink to='/'>
      <img src={`${brandLogo}`} className="img-fluid" alt="Tokern"/>
    </LogoLink>
  )
}
