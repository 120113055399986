import React from "react";
import { StaticQuery, graphql } from "gatsby";

import CustomImage from './image'

const Image = ({ filename, alt, ...styles }) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find((n) => {
        return n.node.relativePath.includes(filename);
      });
      if (!image) {
        return null;
      }
      return (
        <CustomImage
          {...styles}
          alt={alt}
          fluid={image.node.childImageSharp.fluid}
        />
      );
    }}
  />
);
export default Image;
